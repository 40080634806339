import React from 'react';

const Home = () => {
  return (
    <div className="relative h-screen bg-cover bg-center" style={{ backgroundImage: "url('https://res.cloudinary.com/dz2fc3ioz/image/upload/v1729355145/laperitum/pexels-ekaterina-bolovtsova-6077797_wltfjh.jpg')" }}>
      {/* Fading overlay */}
      <div className="absolute inset-0 bg-gradient-to-t from-[#C1A17C] to-transparent h-full w-full"></div>

      {/* Bottom-left aligned content */}
      <div className="absolute bottom-10 left-10 z-10 text-left text-white space-y-4">
        {/* Under Construction Message */}
        <p className="text-6xl md:text-7xl">Under Construction</p>

        {/* We'll be back soon */}
        <p className="text-4xl">We'll be back soon</p>

        {/* Contact details */}
        <div className="text-md space-y-2">
          <p>
            <a href="tel:+2348037709418" className="hover:underline">
              +2348037709418
            </a>
          </p>
          <p>
            <a href="mailto:enquiries@laperitum.com" className="hover:underline">
              enquiries@laperitum.com
            </a>
          </p>
          <p>
            <a href="mailto:bolanle.opadokun@laperitum.com" className="hover:underline">
              bolanle.opadokun@laperitum.com
            </a>
          </p>
        </div>

        {/* LinkedIn Icon */}
        <div className="flex items-center">
          <a href="https://www.linkedin.com/in/bolanle-opadokun-07123326/" target="_blank" rel="noopener noreferrer" className="hover:opacity-75">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6 text-white"
            >
              <path d="M19.5 0h-15C2.01 0 0 2.01 0 4.5v15C0 21.99 2.01 24 4.5 24h15c2.49 0 4.5-2.01 4.5-4.5v-15C24 2.01 21.99 0 19.5 0zM7.5 20h-3V9h3v11zm-1.5-13.1a1.7 1.7 0 1 1 0-3.4 1.7 1.7 0 0 1 0 3.4zm16.5 13.1h-3v-5.5c0-1.31-.03-3.02-1.83-3.02-1.83 0-2.1 1.43-2.1 2.93v5.59h-3v-11h3v1.5h.04c.42-.79 1.45-1.62 2.98-1.62 3.18 0 3.77 2.09 3.77 4.8v6.32z"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;