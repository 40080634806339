import React, { useState } from 'react';
import { logo } from '../Assets';
import { useLocation } from 'react-router-dom'; // Import useLocation

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation(); // Get current location (URL)

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  // Menu items array
  const menuItems = [
    { name: 'Home', path: '/' },
    { name: '', path: '/about' },
    { name: '', path: '/contact' },
  ];

  // Function to check if the current route is active
  const isActive = (path) => location.pathname === path;

  return (
    <nav className="fixed top-0 left-0 w-full bg-[#ECE3D8] border-b-2 border-gray-200 z-50">
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        {/* Logo */}
        <div className="text-2xl font-semibold">
          <a href="/">
            <img src={logo} alt="Logo" />
          </a>
        </div>

        {/* Menu - hidden on mobile, visible on larger screens */}
        <div className="hidden md:flex space-x-6">
          {menuItems.map((item) => (
            <a
              key={item.path}
              href={item.path}
              className={`${
                isActive(item.path)
                  ? 'bg-[#2F3545] text-[#C1A17C]'
                  : 'text-[#1e1e1e] hover:bg-[#2F3545] hover:text-[#C1A17C]'
              } px-4 py-2 rounded-md`}
            >
              {item.name}
            </a>
          ))}
        </div>

        {/* Blog Post Button */}
        <div className="hidden md:block">
          <a
            href="/blog"
            className="bg-[#C1A17C] text-[#1e1e1e] px-4 py-2 rounded-md border-2 border-gray-200"
          >
            Blog Post
          </a>
        </div>

        {/* Mobile menu button */}
        <div className="md:hidden">
          <button onClick={toggleMobileMenu} className="focus:outline-none">
            <svg
              className="w-6 h-6 text-[#1e1e1e]"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile menu - only shown when isMobileMenuOpen is true */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-[#C1A17C]/30 px-4 py-2">
          {menuItems.map((item) => (
            <a
              key={item.path}
              href={item.path}
              className={`${
                isActive(item.path)
                  ? 'bg-[#2F3545] text-[#C1A17C]'
                  : 'text-[#1e1e1e] hover:bg-[#2F3545] hover:text-[#C1A17C]'
              } block px-4 py-2 rounded-md`}
            >
              {item.name}
            </a>
          ))}
          <a
            href="/blog"
            className="block bg-[#C1A17C] text-[#1e1e1e] px-4 py-2 rounded-md border-b-2 border-gray-200"
          >
            Blog Post
          </a>
        </div>
      )}
    </nav>
  );
};

export default Navbar;